<template>
  <section id="acompanhamento-de-denuncia" class="my-3 bg-white">
    <div class="container">
      <div v-if="denuncia_carregada===0" id="get-denuncia-by-code" class="row">
        <div class="col-12 title text-primary"><h2><strong>Acompanhar denúncia</strong></h2>  </div>
        <div class="col-12">
          <div class="card">
            <div class="card-title text-start">
              <h5 class="px-3 py-2 text-primary">Obter a informação a dua denúncia</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="input-section col-12 col-md-12">
                  <div class="mb-2 text-black-50 text-start"><label>Código de denúncia</label></div>
                  <input class="form form-control input text-text" v-model="codigo" type="text" />
                </div>
                <div class="col-12 text-end mt-2">
                  <button class="btn btn-primary" @click="consultar()">Consultar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="denuncia_carregada===2" id="get-denuncia-by-code-erro" class="row">
        <div class="col-12 title text-primary"><h2><strong>Acompanhar da denúncia...</strong></h2>  </div>
        <div class="col-12">
          <div class="card">
            <div class="card-title text-start">
              <h5 class="px-3 py-2 text-primary">Verificar código</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 text-center mt-2">
                  <div class="mb-2 text-black-50"><label>Não foi encontrada nenhuma denúncia com o código fornecido.</label></div>
                  <button class="btn btn-primary" @click="denuncia_carregada=0">Voltar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="denuncia_carregada===1" id="acompanhar-denuncia" class="row text-start">

        <div class="col-12 title text-primary"><h2 class="d-inline-block"><strong>Acompanhar denúncia</strong></h2> <button class="btn btn-primary imprimir-denuncia mx-1" @click="imprimirDenuncia()">Imprimir denúncia</button>   <button class="btn btn-primary imprimir-denuncia mx-1" @click="denuncia_carregada=0">Nova consulta</button></div>

        <div class="card col-lg-12 mt-4">
          <div class="card-title text-start">
            <h5 class="px-3 py-2 text-primary">Estado denúncia</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <button class="btn btn-success imprimir-denuncia mx-1" disabled>{{ denuncia.estado }} </button>
            </div>
          </div>
        </div>

        <div class="card col-lg-12 mt-4">
          <div class="card-title text-start">
            <h5 class="px-3 py-2 text-primary">Dados do denunciante</h5>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="input-section col-12 col-md-10">
                <div class="mb-2 text-black-50"><label>Email do denunciante <span class="text-primary">*</span></label></div>
                <div class="dado-mostrar bg-light" >{{ denuncia.denunciante.email }}</div>
              </div>

              <div class="input-section col-12 col-md-2">
                <div class="mb-2 text-black-50"><label>Tipo de denúncia</label></div>
                  <div class="dado-mostrar bg-light" >{{ denuncia.anonima?'Anonima':'Não anonima' }}</div>
              </div>

              <div class="mt-4 input-section col-12 col-md-6" v-if="!denuncia.anonima">
                <div class="mb-2 text-black-50"><label>Nome do denunciante <span class="text-primary">*</span></label></div>
                <div class="dado-mostrar bg-light">{{ imprimirDado(denuncia.denunciante.nome) }}</div>
              </div>

              <div class="mt-4 input-section  col-12 col-md-6" v-if="!denuncia.anonima">
                <div class="mb-2 text-black-50"><label>Telefone Denunciante</label></div>
                <div class="dado-mostrar bg-light">{{ imprimirDado(denuncia.denunciante.telefone) }}</div>
              </div>

              <div class="col-md-12 mt-2 alert alert-info mb-0 " v-if="denuncia.anonima">
                A sua denúncia foi enviada de forma anónima, por isso não ficaram guardados os seus dados pessoais no nosso sistema.
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-title text-start">
            <h5 class="px-3 py-2 text-primary">Dados do denunciado</h5>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="input-section col-12 col-md-6">
                <div class="mb-2 text-black-50"><label>Nome do denunciado <span class="text-primary">*</span></label></div>
                <div class="dado-mostrar bg-light" >{{ imprimirDado(denuncia.denunciado.nome) }}</div>
              </div>

              <div class="input-section col-12 col-md-6">
                <div class="mb-2 text-black-50"><label>Telefone do denunciado</label></div>
                <div class="dado-mostrar bg-light" >{{ imprimirDado(denuncia.denunciado.telefone) }}</div>
              </div>

              <div class="mt-4 input-section col-12 col-md-6">
                <div class="mb-2 text-black-50"><label>Email do denunciado</label></div>
                <div class="dado-mostrar bg-light" >{{ imprimirDado(denuncia.denunciado.email) }}</div>
              </div>

              <div class="mt-4 input-section col-12 col-md-6">
                <div class="mb-2 text-black-50"><label>Empresa relacionada com o denunciado <span class="text-primary">*</span></label></div>
                <div class="dado-mostrar bg-light" >{{ imprimirDado(denuncia.denunciado.empresa) }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-4">
          <div class="card-title text-start">
            <h5 class="px-3 py-2 text-primary">Dados da denúncia</h5>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="input-section col-6">
                <div class="mb-2 text-black-50"><label>Data Inicio Ocorrência <span class="text-primary">*</span></label></div>
                <div class="dado-mostrar bg-light" >{{ imprimirDado(denuncia.data_inicio_ocorrencia) }}</div>
              </div>

<!--              <div class="input-section col-6">-->
<!--                <div class="mb-2 text-black-50"><label>Data Inicio Ocorrência <span class="text-primary">*</span></label></div>-->
<!--                <div class="dado-mostrar bg-light" >{{ imprimirDado(denuncia.data_fim_ocorrencia) }}</div>-->
<!--              </div>-->

              <div class="input-section col-6">
                <div class="mb-2 text-black-50"><label>Comentários sobre a data * <span class="text-primary">*</span></label></div>

                <div class="dado-mostrar bg-light" v-if="denuncia.inicio_ocorrencia_comment===1" >Tenho a certeza absoluta sobre esta data</div>
                <div class="dado-mostrar bg-light" v-else-if="denuncia.inicio_ocorrencia_comment===2" >Não tenho a certeza absoluta sobre esta data</div>
                <div class="dado-mostrar bg-light" v-else-if="denuncia.inicio_ocorrencia_comment===3" >Ocorrência(s) a decorrer</div>
                <div class="dado-mostrar bg-light" v-else >-</div>

              </div>

              <div class="input-section col-6 mt-4">
                <div class="mb-2 text-black-50"><label>Com que regularidade aconteceu? <span class="text-primary">*</span></label></div>

                <div class="dado-mostrar bg-light" v-if="denuncia.regularidade_ocorrencia===1" >Pelo menos uma vez</div>
                <div class="dado-mostrar bg-light" v-else-if="denuncia.regularidade_ocorrencia===2" >Esporádico</div>
                <div class="dado-mostrar bg-light" v-else-if="denuncia.regularidade_ocorrencia===3" >Frequente</div>
                <div class="dado-mostrar bg-light" v-else-if="denuncia.regularidade_ocorrencia===4" >Constante</div>
                <div class="dado-mostrar bg-light" v-else >-</div>

              </div>

              <div class="mt-4 input-section col-12">
                <div class="mb-2 text-black-50"><label>Motivo da denúncia <span class="text-primary">*</span></label></div>
                <div class="dado-mostrar bg-light" >{{ imprimirDado(denuncia.motivo) }}</div>
              </div>

              <div class="mt-4 input-section col-12 pb-3">
                <div class="mb-2 text-black-50"><label>Descrição da denúncia <span class="text-primary">*</span></label></div>
                <div class="dado-mostrar bg-light text-area" >{{ imprimirDado(denuncia.descricao) }}</div>
              </div>

            </div>
          </div>
        </div>



        <div class="card mt-4">
          <div class="card-title text-start">
            <h5 class="px-3 py-2 text-primary">Feedback</h5>
          </div>
          <div class="card-body">
            <div class="row">

              <div id="mensagens-contentor" class="input-section col-12 mensagens-contentor" v-if="denuncia.mensagens.length > 0">
                <div class="mensagem-caixa-ext mt-1" :class="mensagem.remetente===1?'eu-caixa':'admin-caixa'" v-for="(mensagem, chave) in denuncia.mensagens" :key="mensagem.id">
                  <div v-if="chave===0 || (chave>0 && denuncia.mensagens[chave-1].remetente!==mensagem.remetente)" :class="[mensagem.remetente===1?'text-start':'text-end', (chave>0 && denuncia.mensagens[chave-1].remetente!==mensagem.remetente)?'mt-4':'']"><label class="text-black-50">{{ mensagem.remetente==1?'Eu':'Administrador' }}</label></div>
                  <div class="nova-mensagem " :class="mensagem.remetente===1?'remetente-1':' remetente-2'" >
                    {{ mensagem.mensagem }}<br>
                    <div class="mensagem-data">{{ mensagem.data }}</div>
                  </div>
                </div>
              </div>

              <div class="input-section col-12 text-center dado-mostrar bg-light" v-else>
                    Ainda não foi enviada nenhuma mensagem para esta denúncia.
              </div>

              <div class="input-section col-12 mt-5">
                <div class="mb-2 text-black-50"><label>Nova mensagem</label></div>
                <div class=""><textarea class="form form-control field-textarea text-text" type="text" required="required" v-model="message_to_send" ></textarea></div>
                <div class="mt-2"><button class="btn btn-primary" @click="enviarMensagem()">Enviar mensagem</button></div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>

import sysdenunciaConfig from "../../../public/js/configurations";

export default {
  name: "AcomanharDenunciaElement",
  data() {
    return {
      // 0 para não preenchida, 1 para preenchida e carregada, 2 para preenchida com erro
      denuncia_carregada: 0,
      estado: '',
      codigo: "",
      message_to_send: "",
      denuncia: {
        anonima: false,
        denunciante: {
          email: '',
          nome: '',
          telefone: '',
        },
        denunciado: {
          nome: '',
          telefone: '',
          email: '',
          empresa: '',
        },
        data_inicio_ocorrencia: '',
        data_fim_ocorrencia: '',
        inicio_ocorrencia_comment: '',
        regularidade_ocorrencia: '',
        motivo: '',
        descricao: '',
        anexos: [
          {
            ficheiro: '',
            descricao: '',
          },
        ],
        mensagens: [
          // {
          //   data: '2023-06-07 17:04',
          //   //1 para denunciante, 2 para admin
          //   remetente: 1,
          //   mensagem: 'Boa tarde, queria informar que o número de telefone do denunciado está errado. Quería corrigir para: +351 932 862 263',
          // },
        ],
      },
      sysdenunciaConfig: sysdenunciaConfig,
    }
  },
  mounted() {
      //Scroll down to the last message
  },
  methods: {
    consultar(){
      let vue = this;

      // let vue = this;

      // URL de la API a consultar
      const apiUrl = sysdenunciaConfig.apiUrl+'/denuncias/consultar';

      // Objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      const data = 'codigo='+this.codigo;


      // Configuración del objeto XMLHttpRequest
      xhr.open('POST', apiUrl);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');


      // Callback que se ejecutará cuando se reciba una respuesta
      xhr.onload = function() {
        if (xhr.status === 200) {
          let data_return = JSON.parse(xhr.response);

          let response = {};

          response.data = data_return;

          if(response.data.status === 1){

            response.data.vars.denuncia = JSON.parse(response.data.vars.denuncia);

            let mensagens = response.data.vars.mensagens;

            let mensagens_array = [];

            let temp_mensagem = {
              data: '',
              //1 para denunciante, 2 para admin
              remetente: 1,
              mensagem: '',
            };

            mensagens = JSON.parse(mensagens);

            //recorrer mensagens
            for(let i = 0; i < mensagens.length; i++){

              temp_mensagem = {
                data: mensagens[i].date,
                remetente: mensagens[i].remetente,
                mensagem: mensagens[i].corpo,
              }
              mensagens_array.push(temp_mensagem);
            }

            vue.denuncia = {
              anonima: response.data.vars.denuncia.anonimato,
              estado: response.data.vars.denuncia.estado,
              denunciante: {
                email: response.data.vars.denuncia.email_denunciante,
                nome: response.data.vars.denuncia.nome_denunciante,
                telefone: response.data.vars.denuncia.telefone_denunciante,
              },
              denunciado: {
                nome: response.data.vars.denuncia.nome_denunciado,
                telefone: response.data.vars.denuncia.telefone_denunciado,
                email: response.data.vars.denuncia.email_denunciado,
                empresa: response.data.vars.denuncia.empresa_denunciado,
              },
              data_inicio_ocorrencia: response.data.vars.denuncia.data_inicio_ocorrencia.date,
              data_fim_ocorrencia: '',
              inicio_ocorrencia_comment: response.data.vars.denuncia.inicio_ocorrencia_comment,
              regularidade_ocorrencia: response.data.vars.denuncia.regularidade_ocorrencia,
              motivo: response.data.vars.denuncia.motivacao,
              descricao: response.data.vars.denuncia.corpo,
              anexos: [
                {
                  ficheiro: 'anexo1.pdf',
                  descricao: 'Anexo 1',
                },
              ],
              mensagens: mensagens_array,
            };

            vue.denuncia_carregada = 1;

            //in 1 sec
            setTimeout(function(){
              //Scroll down to the last message

              try{
                var miDiv = document.getElementById("mensagens-contentor");
                miDiv.scrollTop = miDiv.scrollHeight;
              }catch(e){
                console.log(e);
              }

            }, 100);

          }else{
            vue.denuncia_carregada = 2;
          }
        }
      };

      // Envío de la petición
      xhr.send(data);
    },
    imprimirDado(dado){
      if(dado==''){
        return 'Vazío';
      }else{
        return dado;
      }
    },
    imprimirDenuncia() {
      // Crea un objeto de ventana emergente para imprimir
      var ventanaImpresion = window.open('', '_blank', 'width=600,height=600');

      // Copia el contenido HTML de la página actual en la ventana emergente
      ventanaImpresion.document.write('<html><head><title>Página de impresión</title></head><body>');
      ventanaImpresion.document.write('<style>@media print { body { -webkit-print-color-adjust: exact; } .footer{display: inline-block!important; position: relative!important} .navbar-nav, .imprimir-denuncia{display: none!important}}</style>');
      ventanaImpresion.document.write(document.documentElement.innerHTML);
      ventanaImpresion.document.write('</body></html>');

      // Realiza los ajustes de impresión para evitar cortes en los saltos de página
      ventanaImpresion.document.querySelector('style').insertAdjacentHTML('beforeend', '@page { size: auto; margin: 0mm; }');
      ventanaImpresion.document.querySelector('body').style.margin = '0';
      ventanaImpresion.document.querySelector('body').style.padding = '0';

      // Espera un momento para que los cambios se apliquen antes de imprimir
      setTimeout(function() {
        // Imprime la página actual
        ventanaImpresion.print();

        // Cierra la ventana emergente después de la impresión
        ventanaImpresion.close();
      }, 1500);

    },
    enviarMensagem(){
      let vue = this;

      if(this.message_to_send == ''){
        alert('Não pode enviar uma mensagem vazía');
        return;
      }

      // URL de la API a consultar
      const apiUrl = sysdenunciaConfig.apiUrl+'/denuncias/enviar-mensagem';

      // Objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      const data = 'codigo='+this.codigo+'&mensagem='+this.message_to_send;

      // Configuración del objeto XMLHttpRequest
      xhr.open('POST', apiUrl);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      // Callback que se ejecutará cuando se reciba una respuesta
      xhr.onload = function() {
        if (xhr.status === 200) {
            vue.consultar();
            vue.message_to_send = '';
        }else{
          alert('Erro ao enviar mensagem');
        }
      };

      // Envío de la petición
      xhr.send(data);
    }
  }
}
</script>

<style scoped>
.dado-mostrar{
  border: 1px solid rgba(0,0,0,.1);
  padding: 2px 10px;
  border-radius: 3px;
}

.text-area{
  min-height: 100px;
}

.imprimir-denuncia{
  display: inline-block;
  padding: 5px 15px;
  margin-top: -8px;
  margin-left: 10px;
}

.remetente-1{
  background-color: #95dae5;
  padding: 15px 20px;
  border-radius: 0px 5px 5px 5px;
  position: relative;
}

.remetente-1::before{
  content: '';
  position: absolute;
  top: 0;
  left: -9px;
  border-top: 0px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 10px solid #95dae5;
}

.remetente-2{
  text-align: right;
  background-color: #e7e7e7;
  padding: 15px 20px;
  border-radius: 5px 0px 5px 5px;
  position: relative;
}

.remetente-2::before{
  content: '';
  position: absolute;
  top: 0;
  right: -9px;
  border-top: 0px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 10px solid #e7e7e7;
}

.eu-caixa{
  padding-right: 40px;
}

.admin-caixa{
  padding-left: 40px;
}

.mensagens-contentor{
  padding: 20px;
  max-height: 500px;
  overflow-y: auto;
  background-color: #f2f2f2;
}

.mensagem-data{
  font-size: 10px;
  margin-top: 10px;
  color: #676767;
}

.remetente-1 .mensagem-data{
  text-align: left;
  color: #157d96;
}

.remetente-2 .mensagem-data{
  text-align: right;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

</style>