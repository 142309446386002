<template>
  <div class="container text-start mt-5 mb-5">
    <div class="page-title"><h2 class="text-primary"><b>{{ title }}</b></h2></div>
    <div class="col-md-12 mt-2" v-html="corpo">

    </div>
  </div>
</template>

<script>
export default {
  name: "PoliticaPrivacidade",
  data() {
    return {
      title: "",
      corpo: "",
    }
  },
  mounted() {
    this.getPoliticaPrivacidade();
  },
  methods: {
    async getPoliticaPrivacidade() {
      let vue = this;

      // URL de la API a consultar
      const apiUrl = '/pagina/politica-de-privacidade';

      const data = 'codigo=1';

      // Objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // Configuración del objeto XMLHttpRequest
      xhr.open('POST', apiUrl);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      // Callback que se ejecutará cuando se reciba una respuesta
      xhr.onload = function() {
        if (xhr.status === 200) {

          let data_return = JSON.parse(xhr.response);

          console.log(data_return);
          vue.title = data_return.vars.cms.title;
          vue.corpo = data_return.vars.cms.corpo;
        }
      }

      xhr.send(data);
    },
  },
}
</script>

<style>
 .separador{
   width: 100%;
   border-bottom: 1px solid rgba(0,0,0, .1);
 }
</style>