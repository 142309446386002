<template>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
    </symbol>
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
    </symbol>
    <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
    </symbol>
  </svg>

  <section class="section home-2 bg-light pb-0" id="home">
    <div class="container">
      <div class="row align-items-top">
        <div class="col-lg-6">
          <h1 class=" mb-4 text-dark "> Deseja apresentar uma denúncia laboral? Pode contar conosco para garantir a sua <span class="text-primary fw-medium">segurança e confidencialidade!</span></h1>
          <p class="text-muted fs-17">Na nossa aplicação, proporcionamos um espaço seguro para que possa relatar qualquer situação necessária. Pode fazê-lo de forma anónima, se assim desejar. O nosso objetivo é proteger os seus direitos laborais e oferecer um ambiente de trabalho confiável.</p>
        </div>
        <div class="col-lg-5 offset-lg-1">
          <div class="card p-5 border-0 box-shadow mt-5 mt-lg-0">
            <div class="text-center">
              <h5 class="form-dark mb-4">Formulário de denúncia</h5>
            </div>

            <form class="registration-form" @submit.prevent="initDenuncia()">

              <input type="hidden" name="hidden" value="true" />

              <div class="col-md-12 text-start">

                <label for="validationCustom001" class="form-label">Deseja que a sua denúncia seja anónima? <span
                    class="text-primary">*</span></label>

                <div class="form-check form-switch">
                  <input class="form-check-input" v-model="isAnonymous" type="checkbox" id="flexSwitchCheckDefault">
                </div>

              </div>

              <div class="col-md-12 text-start" v-if="!isAnonymous">
                <label for="validationCustom001" class="form-label">Seu nome <span
                    class="text-primary">*</span></label>
                <input type="text" v-model="name" class="form-control" id="validationCustom001" required>
              </div>
              <div class="col-md-12 mt-4 text-start" >
                <label for="validationCustom002" class="form-label">Seu email <span
                    class="text-primary" v-if="!isAnonymous">*</span></label>
                <input type="email" v-model="email" class="form-control" id="validationCustom002" :required="!isAnonymous">
              </div>

              <div class="col-md-12 mt-4 alert alert-info" v-if="isAnonymous">
                A sua denúncia vai ser enviada de forma anónima, por tanto não é preciso preencher os seus dados pessoais, ainda assim é importante fornecer um email válido para receber uma cópia da sua denúncia e as atualizações da mesma. Pode criar um novo email sem utilizar seus dados pessoais mesmo para esta finalidade.
              </div>

              <button type="submit" class="btn btn-primary w-100 mt-4">Iniciar denúncia <i
                  class="mdi mdi-telegram ms-2"></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-primary py-5 bg-text-content">
      <div class="my-4">
        <div class="container">
          <div class="row align-items-center justify-content-start">
            <div class="col-lg-7">
              <div class="text-start">
                <p class="text-light">Garantimos a segurança dos seus dados. Utilizamos criptografia para protegê-los. Para obter mais informações sobre como tratamos seus dados, consulte nossa política de privacidade. Respeitamos sua privacidade e estamos comprometidos em manter suas informações confidenciais e seguras.</p>
              </div>
            </div>
            <div class="col-lg-3 ">
              <div class="text-lg-end text-start">
                <button class="btn btn-outline-white" @click="politicaPrivacidade()">Política de privacidade</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroElement",
  data() {
    return {
      isAnonymous: false,
      name: "",
      email: "",
    };
  },
  methods: {
    //function to submit the form
    politicaPrivacidade(){
      this.$emit("politica-privacidade", {});
    },
    initDenuncia() {
      if(this.checkForm()){
        this.$emit("init-denuncia", {
          name: this.name,
          email: this.email,
          anonimo: this.isAnonymous,
        });
      }
    },
    checkForm(){
      if(this.isAnonymous){
        if(!this.email || this.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
          return true;
        }
      }else{

        //check name by regex and email
        if(this.name.match(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/) && this.email.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)) {
            return true;
        }

      }

      return false;
    }
  },
}
</script>

<style scoped>

</style>