<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Sign up</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="row g-3 needs-validation">
            <div class="col-md-6">
              <label for="validationCustom01" class="form-label">First name <span
                  class="text-primary">*</span></label>
              <input type="text" class="form-control" id="validationCustom01" value="" required>
              <div class="valid-feedback">
                Looks good!
              </div>
            </div>
            <div class="col-md-6">
              <label for="validationCustom02" class="form-label">Email <span
                  class="text-primary">*</span></label>
              <input type="email" class="form-control" id="validationCustom02" value="" required>
              <div class="valid-feedback">
                Looks good!
              </div>
            </div>
            <div class="col-md-6">
              <label for="validationCustom03" class="form-label">Subject <span
                  class="text-primary">*</span></label>
              <input type="text" class="form-control" id="validationCustom03" required>
              <div class="invalid-feedback">
                Please provide a valid city.
              </div>
            </div>
            <div class="col-md-6">
              <label for="validationCustom05" class="form-label">Password <span
                  class="text-primary">*</span></label>
              <input type="password" class="form-control" id="validationCustom05" required>
              <div class="invalid-feedback">
                Please provide a valid zip.
              </div>
            </div>
            <div class="col-lg-12">
              <label for="validationTextarea" class="form-label">Textarea<span
                  class="text-primary">*</span></label>
              <textarea class="form-control" id="validationTextarea" required></textarea>
              <div class="invalid-feedback">
                You must agree before submitting.
              </div>
            </div>
            <div class="col-12">
              <button class="btn btn-primary" type="submit">Submit form</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalElement"
}
</script>

<style scoped>

</style>