<template>
  <!--Navbar Start-->
  <nav class="navbar navbar-expand-lg fixed-top sticky" id="navbar">
    <div class="container-fluid custom-container">
      <a class="navbar-brand logo text-uppercase" @click="changePage(0)">
        <img :src="sysdenunciaConfig.apiUrl+logo_url" class="logo-light" alt="">
        <!-- <img src="images/logo-light.png" class="logo-dark" alt="" height="28"> -->
      </a>

      <button class="navbar-toggler me-3 order-2 ms-4" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-label="Toggle navigation">
        <i class="mdi mdi-menu"></i>
      </button>

      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mx-auto navbar-center">
          <li class="nav-item">
            <a href="#" class="nav-link" :class="page===0?'active':''" @click="changePage(0)"><i class="fa-solid fa-house"></i> Inicio</a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link " :class="page===1?'active':''" @click="changePage(1)"><i class="fa-regular fa-plus"></i> Criar Denúncia</a>
          </li>

          <li class="nav-item">
            <a href="#" class="nav-link " :class="page===2?'active':''" @click="changePage(2)"><i class="fa-solid fa-eye"></i> Acompanhar denúncia</a>
          </li>



        </ul>
        <!--end navbar-nav-->
      </div>


      <!--end navabar-collapse-->
      <div class="header-menu list-inline d-flex align-items-center mb-0 order-1">
<!--        <div class="list-inline-item dropdown language me-4">-->
<!--          <a href="#" class="header-item" id="userdropdown" data-bs-toggle="dropdown" aria-expanded="false">-->
<!--            <i class="mdi mdi-web f-24 text-black"></i>-->
<!--          </a>-->
<!--          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userdropdown">-->
<!--            <li><a class="dropdown-item" href="#">English</a></li>-->
<!--            <li><a class="dropdown-item" href="#">Deutsch</a></li>-->
<!--          </ul>-->
<!--        </div>-->

      </div>
      <!--end header-menu-->
    </div>
    <!--end container-->
  </nav>
</template>

<script>
import sysdenunciaConfig from "../../../public/js/configurations";

export default {
  name: "HeaderElement",
  props: {
    page: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      logo_url: "",
      sysdenunciaConfig: sysdenunciaConfig,
    };
  },
  mounted() {
    this.getLogo();
  },
  methods: {
    //function to submit the form
    changePage($page) {
      this.$emit("changePage", $page);
    },
    getLogo() {
      const vue = this;

      // URL de la API a consultar
      const apiUrl = sysdenunciaConfig.apiUrl+'/get-logo';

      // Objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      const data = '';

      // Configuración del objeto XMLHttpRequest
      xhr.open('POST', apiUrl);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      // Callback que se ejecutará cuando se reciba una respuesta
      xhr.onload = function() {
        if (xhr.status === 200) {
          vue.logo_url = JSON.parse(xhr.response).vars.url;
        }else{
          vue.logo_url = sysdenunciaConfig.apiUrl+'/images/trigenius_logo_horizontal.png';
        }
      };
      // Envío de la petición
      xhr.send(data);
    },
  },
}
</script>

<style scoped>
  .navbar{
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease-in-out 0s;
    position: relative;
  }

  .logo img{
    max-height: 80px;
    max-width: 300px;
    width: 100%;
    object-fit: contain;
  }

  /*minwidth 992px*/

  @media (min-width: 992px) {
    .navbar-nav{
      margin-right: initial!important;
    }
  }
</style>