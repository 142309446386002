<template>
<div class="body" data-bs-spy="scroll" data-bs-target="#navbar-navlist" data-bs-offset="71">
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <HeaderElement :page="page" @changePage="page = $event" />

  <ModalElement/>

  <HeroElement v-if="page===0" @init-denuncia="iniciarDenuncia" @politica-privacidade="page=3" />

  <DenunciaElement v-if="page===1" :denuncia_inicial="initDenuncia" @limpar-init-denuncia="initDenuncia = {name: '', email: '', anonimo: false}" />

  <AcompanharDenuncia v-if="page===2" />

  <PoliticaPrivacidade v-if="page===3" />

  <FooterElement/>

</div>

</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import HeaderElement from './components/partials/HeaderElement.vue'
import ModalElement from './components/partials/ModalElement.vue'
import HeroElement from './components/HomePage/HeroElement.vue'
import FooterElement from './components/partials/FooterElement.vue'
import DenunciaElement from './components/denuncias/DenunciaElement.vue'
import AcompanharDenuncia from './components/denuncias/AcompanharDenunciaElement.vue'
import PoliticaPrivacidade from './components/Paginas/PoliticaPrivacidade.vue'
import sysdenunciaConfig from "../public/js/configurations";

export default {
  name: 'App',
  components: {
    HeaderElement,
    ModalElement,
    HeroElement,
    FooterElement,
    DenunciaElement,
    AcompanharDenuncia,
    PoliticaPrivacidade
  },
  data() {
    return {
      page: 0,
      initDenuncia: {
        name: '',
        email: '',
        anonimo: false,
      },
      sysdenunciaConfig: sysdenunciaConfig,
    };
  },
  mounted(){

  },
  methods: {
    iniciarDenuncia(data){
      this.initDenuncia = data;
      //Apagar o nome do denunciante caso esteja selecionado o anonimo
      if(this.initDenuncia.anonimo===true) {
        this.initDenuncia.name = '';
      }
      this.page = 1;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.body{
  min-height: 100vh;
  position: relative;
  padding-bottom: 120px;
}
</style>
