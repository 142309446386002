import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../public/css/tiny-slider.css'
import '../public/css/swiper.min.css'
import '../public/css/materialdesignicons.min.css'
import '../public/css/style.css'
import sysdenunciaConfig from '../public/js/configurations.js'

//import axios





createApp(App).use(sysdenunciaConfig).mount('#app')
