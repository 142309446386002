<template>
  <section id="criar-denuncia" class="my-3 bg-white">
    <div class="container">
      <div id="formulario-denuncia" v-if="formEnviado==0" class="row">
        <div class="col-12 title text-primary"><h2><strong>Formulário de denúncia</strong></h2></div>
        <div class="col-12">
          <form class="denuncia-form text-start py-3" @submit.prevent="enviarForm()" >

            <div class="card">
              <div class="card-title text-start">
                <h5 class="px-3 py-2 text-primary">Configuração de denúncia</h5>
              </div>
              <div class="card-body">
                <div class="input-section col-12 col-md-10">
                  <div class="mb-2 text-black-50"><label>Tipología <span class="text-primary">*</span></label></div>
                  <select class="form-select" name="tipologia" aria-label="Selecione tipología" v-model="denunciaForm.id_tipologia" required>
                    <option value="">Selecione tipología</option>
                    <option :value="tipologia.id" v-for="tipologia in tipologias" :key="tipologia.id">{{ tipologia.nome }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-title text-start">
                <h5 class="px-3 py-2 text-primary">Dados do denunciante</h5>
              </div>
              <div class="card-body">
                <div class="row">


                  <div class="input-section col-12 col-md-10">
                    <div class="mb-2 text-black-50"><label>Email do denunciante <span class="text-primary" v-if="!denunciaForm.anonima">*</span></label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.denunciante.email" type="text" :required="!denunciaForm.anonima" />
                  </div>

                  <div class="input-section col-12 col-md-2">
                    <div class="mb-2 text-black-50"><label>Denúncia anónima</label></div>
                    <div class="d-inline-block"><div class="form-check form-switch">
                      <input class="form-check-input text-text" v-model="denunciaForm.anonima" type="checkbox" id="flexSwitchCheckDefault">
                    </div></div>
                  </div>

                  <div class="mt-4 input-section col-12 col-md-6" v-if="!denunciaForm.anonima">
                    <div class="mb-2 text-black-50"><label>Nome do denunciante <span class="text-primary">*</span></label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.denunciante.nome" type="text" required="required" />
                  </div>

                  <div class="mt-4 input-section  col-12 col-md-6" v-if="!denunciaForm.anonima">
                    <div class="mb-2 text-black-50"><label>Telefone Denunciante</label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.denunciante.telefone" type="text" />
                  </div>

                  <div class="col-md-12 mt-2 alert alert-info mb-0 " v-if="denunciaForm.anonima">
                    A sua denúncia vai ser enviada de forma anónima, por tanto não é preciso preencher os seus dados pessoais, ainda assim é importante fornecer um email válido para receber uma cópia da sua denúncia e as atualizações da mesma. Pode criar um novo email sem utilizar seus dados pessoais mesmo para esta finalidade.
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-title text-start">
                <h5 class="px-3 py-2 text-primary">Dados do denunciado</h5>
              </div>
              <div class="card-body">
                <div class="row">

                  <div class="input-section col-12 col-md-6">
                    <div class="mb-2 text-black-50"><label>Nome do denunciado <span class="text-primary">*</span></label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.denunciado.nome" type="text" required="required" />
                  </div>

                  <div class="input-section col-12 col-md-6">
                    <div class="mb-2 text-black-50"><label>Telefone do denunciado</label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.denunciado.telefone" type="text" />
                  </div>

                  <div class="mt-4 input-section col-12 col-md-6">
                    <div class="mb-2 text-black-50"><label>Email do denunciado</label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.denunciado.email" type="text" />
                  </div>

                  <div class="mt-4 input-section col-12 col-md-6">
                    <div class="mb-2 text-black-50"><label>Empresa relacionada com o denunciado <span class="text-primary">*</span></label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.denunciado.empresa" type="text" required="required" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-title text-start">
                <h5 class="px-3 py-2 text-primary">Dados da denúncia</h5>
              </div>
              <div class="card-body">
                <div class="row">

                  <div class="input-section col-12 text-end">
                    <div class="mb-2 text-black-50 text-start"><label>Data Inicio Ocorrência <span class="text-primary">*</span></label></div>
                    <input class="form field-date text-text" v-model="denunciaForm.data_inicio_ocorrencia" type="date" required="required" />
                  </div>

<!--                  <div class="input-section col-6">-->
<!--                    <div class="mb-2 text-black-50"><label>Data Fim Ocorrência <span class="text-primary">*</span></label></div>-->
<!--                    <input class="form field-date text-text" v-model="denunciaForm.data_fim_ocorrencia" type="date" required="required" />-->
<!--                  </div>-->

                  <div class="onput-section col-6 mt-4">
                    <div class="mb-2 text-black-50"><label>Comentários sobre a data <span class="text-primary">*</span></label></div>
                    <div class="form-check">
                      <input class="form-check-input custom-radio" value="1" type="radio" name="data_comment" id="flexRadioDefault1" v-model="denunciaForm.data_comment">
                      <label class="form-check-label" for="flexRadioDefault1">
                        Tenho a certeza absoluta sobre esta data
                      </label>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input custom-radio" value="2" type="radio" name="data_comment" id="flexRadioDefault2" v-model="denunciaForm.data_comment">
                      <label class="form-check-label" for="flexRadioDefault2">
                        Não tenho a certeza absoluta sobre esta data
                      </label>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input custom-radio" value="3" type="radio" name="data_comment" id="flexRadioDefault3" v-model="denunciaForm.data_comment">
                      <label class="form-check-label" for="flexRadioDefault3">
                        Ocorrência(s) a decorrer
                      </label>
                    </div>
                  </div>

                  <div class="onput-section col-6 mt-4">
                    <div class="mb-2 text-black-50"><label>Com que regularidade aconteceu? <span class="text-primary">*</span></label></div>
                    <div class="form-check">
                      <input class="form-check-input custom-radio" value="1" type="radio" name="frequencia" id="regularidade1" v-model="denunciaForm.frequencia">
                      <label class="form-check-label" for="regularidade1">
                        Pelo menos uma vez
                      </label>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input custom-radio" value="2" type="radio" name="frequencia" id="regularidade2" v-model="denunciaForm.frequencia">
                      <label class="form-check-label" for="regularidade2">
                        Esporádico
                      </label>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input custom-radio" value="3" type="radio" name="frequencia" id="regularidade3" v-model="denunciaForm.frequencia">
                      <label class="form-check-label" for="regularidade3">
                        Frequente
                      </label>
                    </div>

                    <div class="form-check">
                      <input class="form-check-input custom-radio" value="4" type="radio" name="frequencia" id="regularidade4" v-model="denunciaForm.frequencia">
                      <label class="form-check-label" for="regularidade4">
                        Constante
                      </label>
                    </div>
                  </div>


                  <div class="mt-4 input-section col-12">
                    <div class="mb-2 text-black-50"><label>Motivo da denúncia <span class="text-primary">*</span></label></div>
                    <input class="form form-control input text-text" v-model="denunciaForm.motivo" type="text" required="required" />
                  </div>

                  <div class="mt-4 input-section col-12">
                    <div class="mb-2 text-black-50"><label>Descrição da denúncia <span class="text-primary">*</span></label></div>
                    <textarea class="form form-control field-textarea text-text" v-model="denunciaForm.descricao" type="text" required="required" ></textarea>
                  </div>

                </div>
              </div>
            </div>

            <div class="card mt-4 anexos">
              <div class="card-title text-start">
                <h5 class="px-3 py-2 text-primary">Anexos (Total Max: <div class="d-inline-block" v-html="max_post_size"></div>)</h5>
                <div class="adicionar-anexo btn btn-primary" v-if="denunciaForm.anexos.length > 0" @click="adicionarAnexo()">Novo Anexo</div>
              </div>
              <div class="card-body">
                <div class="row">


                  <div class="col-12 col-md-6 col-lg-4 p-2" :class="anexo.msg_preencher===true?'bounce':''" v-for="(anexo, key) in denunciaForm.anexos" :key="anexo.id">
                    <div class="anexo-element" :class="anexo.msg_preencher===true?'':'bg-light'" >
                      <div class="mb-2 text-primary text-left">
                        <label><strong>Anexo {{ key+1 }} (Max: <div class="d-inline-block" v-html="upload_max_filesize"></div>)</strong></label>
                        <div class="delete-anexo" @click="apagarAnexo(key)">X</div>
                      </div>
                      <div><input class="form-control" type="file" ref="fileInput"  id="formFileMultiple" @change="handleFileChange(key)"></div>
                      <div class="mt-3"><textarea  class="form form-control field-textarea text-text" placeholder="Descrição do anexo" v-model="anexo.descricao" type="text" ></textarea></div>
                    </div>
                  </div>

                  <div class="col-12 p-2 text-center" v-if="denunciaForm.anexos.length === 0">
                    Nenhum anexo adicionado, clique <div class="adicionar-anexo2 btn btn-primary" @click="adicionarAnexo()">Novo Anexo</div> se deseja carregar algum ficheiro.
                  </div>

                </div>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12 text-center"><button class="btn btn-primary">Enviar denúncia</button></div>
            </div>


          </form>
        </div>
      </div>

      <div class="row" v-if="formEnviado!==0">
        <div class="card">
          <div class="card-title text-start">
            <h4 class="px-3 py-2 text-primary">{{ apiMessage }}</h4>
          </div>
          <div class="card-body" >

            <!-- Se a denuncia for enviada com sucesso -->
              <div class="text-start text-black-50" v-if="formEnviado===1">
                A sua denúncia foi enviada com sucesso, foi enviada uma cópia da informação da denúncia para o seu email. Vamos analisar a informação se for preciso entraremos em contacto consigo pelos meios de contactos fornecidos por si no formulário da denúncia.
              </div>

              <div class="mt-4 text-start text-black-50" v-if="formEnviado===1">
                Para visualizar o estado da sua denúncia vai precisar do seguinte código de acesso, guarde-o bem:
                <div class="mt-2" v-if="!showCodigo"><button class="btn btn-primary py-2 px-3" @click="showCodigo=true">Ver código</button></div>
                <div class="text-secondary mt-1" v-else><strong>{{ codigo }}</strong></div>
              </div>

              <!-- Se a denuncia não for enviada com sucesso -->
              <div class="text-start text-black-50" v-if="formEnviado===2">
                Aconteceu um erro ao enviar a sua denúncia, por favor verifique que todos os dados do formulário da denúncia estão preenchidos corretamente e tente novamente.
              </div>

              <div class="mt-4 text-start text-black-50" v-if="formEnviado===2">
                <div class="mt-2"><button class="btn btn-primary py-2 px-3" @click="formEnviado=0; apiMessage=''; showCodigo=false; codigo=''">Voltar ao fomulário</button></div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

// import axios from 'axios'

import sysdenunciaConfig from "../../../public/js/configurations";

export default {
  name: "DenunciaElement",
  props: {
    denuncia_inicial: Object
  },
  data() {
    return {
      // 0 = não enviado, 1 = enviado com sucesso, 2 = erro ao enviar
      formEnviado: 0,
      max_post_size: 'A carregar...',
      upload_max_filesize: 'A carregar...',
      apiMessage: '',
      showCodigo: false,
      codigo: '',
      tipologias: {},
      denunciaForm: {
        anonima: false,
        id_tipologia: '',
        denunciante: {
          email: '',
          nome: '',
          telefone: '',
        },
        sysdenunciaConfig: sysdenunciaConfig,
        denunciado: {
          nome: '',
          telefone: '',
          email: '',
          empresa: '',
        },
        data_inicio_ocorrencia: '',
        data_fim_ocorrencia: '',
        data_comment: 1,
        frequencia: 1,
        motivo: '',
        descricao: '',
        anexos: [
          {
            ficheiro: '',
            descricao: '',
            msg_preencher: false,
          },
        ]
      },
    };
  },
  methods: {
    apagarAnexo(key) {
      this.denunciaForm.anexos.splice(key, 1);
    },
    carregarTipologias() {
      let vue = this;

      // URL de la API a consultar
      const apiUrl = sysdenunciaConfig.apiUrl+'/denuncias/getTipologias';

      // Objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // const data = 'datos=' + JSON.stringify(this.denunciaForm);

      // Crear un objeto FormData
      const formData = new FormData();

      // Añadir campo al FormData
      formData.append('dados', JSON.stringify({}));


      xhr.open('POST', apiUrl);
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      // Callback que se ejecutará cuando se reciba una respuesta
      xhr.onload = function() {
        if (xhr.status === 200) {
          let data_return = JSON.parse(xhr.response);
          let response = {};
          response.data = data_return;
          vue.tipologias = response.data.vars.tipologias;
        }
      }
      xhr.send(formData);
    },
    adicionarAnexo() {
      // just when last anexo has file
      if (this.denunciaForm.anexos[this.denunciaForm.anexos.length - 1].ficheiro) {
        this.denunciaForm.anexos.push({
          ficheiro: '',
          descricao: '',
          msg_preencher: false,
        });
      }else{
        this.denunciaForm.anexos[this.denunciaForm.anexos.length - 1].msg_preencher = true;
        // bounce animation to last anexo
        setTimeout(() => {
          this.denunciaForm.anexos[this.denunciaForm.anexos.length - 1].msg_preencher = false;
        }, 400);

      }
    },
    handleFileChange(key) {
      const file = this.$refs.fileInput[key].files[0];
      if (file) {
        this.denunciaForm.anexos[key].ficheiro = file;
      }
    },

    enviarForm(){
      let vue = this;

      // URL de la API a consultar
      const apiUrl = sysdenunciaConfig.apiUrl+'/denuncias/criar';

      // Objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // const data = 'datos=' + JSON.stringify(this.denunciaForm);

      // Crear un objeto FormData
      const formData = new FormData();

      // Añadir campo al FormData
      formData.append('dados', JSON.stringify(this.denunciaForm));

      // const archivo = this.$refs.fileInput.files;
      for(let i=0; i<this.denunciaForm.anexos.length; i++){
        formData.append('anexos[]', this.denunciaForm.anexos[i].ficheiro);
      }

      // Configuración del objeto XMLHttpRequest
      xhr.open('POST', apiUrl);
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      // Callback que se ejecutará cuando se reciba una respuesta
      xhr.onload = function() {
        if (xhr.status === 200) {
          let data_return = JSON.parse(xhr.response);

          let response = {};

          response.data = data_return;

          if(response.data.status === 1){
            vue.formEnviado = 1;
            vue.apiMessage = response.data.message;
            vue.codigo = response.data.vars.codigo;
          }else{
            vue.formEnviado = 2;
            vue.apiMessage = response.data.message;
          }

        }
      }

      xhr.send(formData);
    },

    getMaxPostSize(){
      let vue = this;
      // URL de la API a consultar
      const apiUrl = sysdenunciaConfig.apiUrl+'/denuncias/criar/getmaxpost';

      // Objeto XMLHttpRequest
      const xhr = new XMLHttpRequest();

      // Crear un objeto FormData
      const formData = new FormData();

      // Añadir campo al FormData
      formData.append('dados', JSON.stringify(this.denunciaForm));

      // Configuración del objeto XMLHttpRequest
      xhr.open('POST', apiUrl);
      // xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      // Callback que se ejecutará cuando se reciba una respuesta
      xhr.onload = function() {
        if (xhr.status === 200) {
          let data_return = JSON.parse(xhr.response);
          vue.max_post_size = data_return.vars.max_post_size;
          vue.upload_max_filesize = data_return.vars.upload_max_filesize;
        }
      }
      xhr.send(formData);
    }
  },
  mounted() {
      this.getMaxPostSize();
      this.carregarTipologias();
      this.denunciaForm.anonima = this.denuncia_inicial.anonimo;
      this.denunciaForm.denunciante.nome = this.denuncia_inicial.name;
      this.denunciaForm.denunciante.email = this.denuncia_inicial.email;
      this.$emit("limpar-init-denuncia", {});

  },
}
</script>

<style scoped>
  /*bounce animation*/
  .bounce {
    animation: bounce 0.4s infinite;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }

  .bounce .anexo-element{
    animation: bounce-color 0.4s infinite;
  }

  @keyframes bounce-color {
    0%,
    100% {
      background-color: #f5f7fa;
    }
    50% {
      background-color: #cde7f3;
    }
  }

  .denuncia-form input, .denuncia-form textarea{
    /*max-width: 500px;*/
    /*display: inline-block;*/
  }

  .anexo-element{
    border: 1px solid var(--bs-border-color);
    padding: 15px 20px;
    border-radius: 10px;
  }

  .delete-anexo{
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    font-weight: bold;
  }

  .delete-anexo:hover{
    color: var(--highlight);
  }

  .adicionar-anexo{
    display: inline-block;
    cursor: pointer;
    top: 10px;
    right: 20px;
    position: absolute;
    padding: 5px 10px;
  }

  .adicionar-anexo2{
    padding: 5px 10px;
  }

  .custom-radio{
    width: 18px;
    height: 18px;
    min-width: initial;
    min-height: initial;
  }
</style>