<template>
  <section class="footer py-5 bg-light">
    <div class="container">
      <div class="row">
       <div class="col-12 text-center">© Copyright {{ currentYear }} - Sistema de denúncias por <a href="https://trigenius.pt">TRIGÉNIUS</a></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterElement",
  data() {
    return {
      currentYear: '',
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
  },
}
</script>

<style scoped>
.footer{
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>